import { render, staticRenderFns } from "./assist_travelers_details.vue?vue&type=template&id=bbfbbd26&scoped=true&"
import script from "./assist_travelers_details.vue?vue&type=script&lang=js&"
export * from "./assist_travelers_details.vue?vue&type=script&lang=js&"
import style0 from "./assist_travelers_details.vue?vue&type=style&index=0&id=bbfbbd26&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbfbbd26",
  null
  
)

export default component.exports